<template>
  <div class="game-search">
    <mly-header style2></mly-header>
    <mly-game-list :game-list="gameList" v-if="gameList.length>0"></mly-game-list>
  </div>
</template>

<script>
export default {
  created() {
    this.gameList = JSON.parse(sessionStorage.getItem(this.keyword));
    // sessionStorage.clear();
  },
  data() {
    return {
      gameList: []
    };
  },
  computed: {
    keyword() {
      return this.$route.params.keyword;
    }
  },
  methods: {}
};
</script>

<style>
</style>
